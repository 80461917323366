import React from 'react';
import { GoogleMap, Marker, InfoWindow, KmlLayer, TrafficLayer, DrawingManager, useLoadScript } from '@react-google-maps/api';
import { STYLE_CONTAI, MAPA_OPTION, MAPA_CENTER, KEY_GOOGLE } from "./mapConfig";
import { ICON_CFG } from "./iconConfig"


import Loading  from '../Loading';
import Error500 from '../Errors/500';

const Index = (props) => {
    const [map, setMap] = React.useState(null);

    
    const { moviles, rutas } = props;
    const [markers, setMarkers] = React.useState([]);
    const [selected, setSelected] = React.useState(null);

    const { isLoaded, loadError } = useLoadScript({
        // googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
        googleMapsApiKey: KEY_GOOGLE
    });
 
    const mapRef = React.useRef();
    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, []);

    const panTo = React.useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(14);
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);
 
    
    const renderRutas = (listado) => {
        let kml = [];
        listado.map( (obj, i) => kml.push(<KmlLayer key={i} url={obj} />) );
        return kml;
    }

    const crearMarker = (obj) => {
        const { Codigo, Patente, Movil} = obj;
        const eve = obj.Eventos[0];
        const { Heading, Velocidad, Fecha, Hora } = eve;
        const pos = { lat : eve.Latitud, lng: eve.Longitud };
        const marker = renderMarker(Codigo, Patente, Movil, pos, Heading, Velocidad, Hora, Fecha);
        return marker;
    }

    const renderMarker = (cod, ppu, mov,  pos, gra, spd, hor, fec) => {
        const tittle = `MAQ: ${mov}|PPU: ${ppu}`;
        return (
          <Marker  
            key       = { cod } 
            title     = { tittle }
            id        = { cod } 
            name      = { ppu }
            position  = { pos } 
            mapCenter = { pos }
            draggable = { false }
            //onClick   = { this.onMarkerClick }
            icon      = { ICON_CFG(gra, spd) }
            //label     = { ppu }
            hora      = { hor }
            fecha     = { fec }
            velocidad = { spd }
            grados    = { gra }
            movil     = { mov }
          />
        )
    }

    if (loadError) return <Error500 />;
    if (!isLoaded) return <Loading />;

    return (
        <div className="boxMapa">
            <GoogleMap 
                mapContainerStyle={ STYLE_CONTAI } 
                options={ MAPA_OPTION }
                onLoad={onMapLoad}
                onUnmount={onUnmount} 
                zoom={1} 
                center={ MAPA_CENTER } 
            >
                { rutas.length > 0 ? renderRutas(rutas) : null }
                { moviles.map(obj => (obj.Eventos.length > 0 ? crearMarker(obj): null) )}
                
                <TrafficLayer />

            </GoogleMap>
        </div>
    )
}

export default React.memo(Index);