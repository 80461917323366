import React from 'react';
import MonitorFrecuencia from './componets/Monitores';

import './index.css';

const App = () => {
  return (
    <div className="background">
      <MonitorFrecuencia />
    </div>
  );
}

export default App;
