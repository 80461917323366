const ICON_SVG = "M 0.15819685,-1.7313924 C -0.20068547,-0.57366228 -0.77102389,0.64638983 -1.8776818,2.2570557 0.47577564,1.1195351 -0.17423351,1.1153647 2.1052267,2.2977377 1.0806048,0.75653464 0.54081929,-0.5637517 0.15819685,-1.7313924 Z";
const ICON_CIR = "m 4.7481291,0.74493532 c -0.067158,-0.2743055 -0.282378,-0.384009 -0.6050588,-0.493741 -0.3181405,-0.1082135 -1.0804599,-0.237573 -1.6430327,-0.241215 -0.5625478,0.00435 -1.3248839,0.1331255 -1.64300741,0.241215 -0.32270604,0.1097685 -0.53781807,0.2194355 -0.60502583,0.493741 L 0.00997907,2.2655954 v 2.0953322 h 0.41679331 v 0.3286005 c 0,0.4006563 0.71845512,0.4006563 0.71845512,0 V 4.3609276 h 1.3263949 0.00415 1.3790166 v 0.3286005 c 0,0.4006563 0.7184389,0.4006563 0.7184389,0 V 4.3609276 h 0.416793 V 2.2655954 Z m -3.5814676,-0.351122 h 1.3091861 1.3420262 c 0.2016892,0 0.2016892,0.246885 0,0.246885 H 2.4733739 1.1666615 c -0.20164755,0 -0.2016643,-0.246885 0,-0.246885 z M 0.7862864,3.7040525 c -0.1903,0 -0.34462153,-0.1259215 -0.34462153,-0.281248 0,-0.1553115 0.15423028,-0.2812475 0.34462153,-0.2812475 0.19030785,0 0.3446463,0.125921 0.3446463,0.2812475 1.65e-5,0.155312 -0.154239,0.281248 -0.3446463,0.281248 z M 2.4758476,2.4258429 H 0.64775365 c -0.17895177,0 -0.21646527,-0.104898 -0.19887502,-0.2106395 L 0.63718615,1.1122748 c 0.025983,-0.13390298 0.0815188,-0.22234348 0.2945893,-0.22234348 H 2.4733571 4.068217 c 0.2130621,0 0.2687058,0.088338 0.2945726,0.22234348 l 0.1883075,1.1029286 c 0.01793,0.105676 -0.019923,0.2106395 -0.198875,0.2106395 z m 1.7379417,1.2782096 c -0.1903,0 -0.3446216,-0.1259215 -0.3446216,-0.281248 0,-0.1553115 0.1542306,-0.2812475 0.3446216,-0.2812475 0.190308,0 0.3446211,0.125921 0.3446211,0.2812475 0,0.155312 -0.1542304,0.281248 -0.3446211,0.281248 z";
//export const ICON_SVG = "M 3.0510727,0.84445667 C 3.0173397,0.70351967 2.9093776,0.64714377 2.7474444,0.59076187 2.5877991,0.53518577 2.2052456,0.46869327 1.9229395,0.46682287 c -0.282306,0.00187 -0.6648595,0.068363 -0.8245049,0.123939 -0.16193325,0.056376 -0.26988865,0.1127519 -0.30362125,0.2536948 L 0.67337045,1.6257859 v 1.0766074 h 0.2091532 V 2.871233 c 0,0.2058591 0.36053445,0.2058591 0.36053445,0 V 2.7023933 h 0.6656197 0.0021 0.6920244 V 2.871233 c 0,0.2058591 0.3605343,0.2058591 0.3605343,0 V 2.7023933 H 3.1725014 V 1.6257859 Z m -1.7972519,-0.180409 h 0.6569759 0.6734608 c 0.1012117,0 0.1012117,0.1268503 0,0.1268503 H 1.9095577 1.2538208 c -0.1011977,0 -0.1012047,-0.1268503 0,-0.1268503 z M 1.0629422,2.3648904 c -0.09551,0 -0.17294275,-0.06471 -0.17294275,-0.1445131 0,-0.079797 0.077433,-0.144513 0.17294275,-0.144513 0.09551,0 0.1729428,0.064716 0.1729428,0.144513 1.36e-5,0.079803 -0.077433,0.1445131 -0.1729428,0.1445131 z M 1.9107967,1.7081294 H 0.99341455 c -0.089815,0 -0.1086242,-0.053917 -0.099797,-0.1082348 L 0.98814155,1.0332 c 0.0129801,-0.068857 0.04092,-0.11424003 0.14782635,-0.11424003 h 0.7735964 0.8003458 c 0.1069137,0 0.1348388,0.045383 0.1478194,0.11424003 l 0.094531,0.5666946 c 0.00881,0.054311 -0.01,0.1082348 -0.099811,0.1082348 z m 0.8721402,0.656761 c -0.09551,0 -0.1729427,-0.06471 -0.1729427,-0.1445131 0,-0.079797 0.07744,-0.144513 0.1729427,-0.144513 0.095503,0 0.1729427,0.064716 0.1729427,0.144513 0,0.079803 -0.077433,0.1445131 -0.1729427,0.1445131 z";

export const ICON_CFG = (ICON_GRA, ICON_SPD) => {
    return {
        path : +ICON_SPD > 0 ? ICON_SVG:ICON_CIR,
        rotation: ICON_GRA,
        scale: +ICON_SPD > 0 ? 3:2,
        strokeColor: '#000',
        strokeWeight: 1.2,
        fillOpacity:2,
        fillColor: ICON_CLR(ICON_SPD),
    }
};

const ICON_CLR = (spd) => {
    if(spd > 0  && spd <= 20){ return '#01DF01' }
    if(spd > 20 && spd <= 40){ return '#2EFEF7' }
    if(spd > 40 && spd <= 60){ return '#F7FE2E' }
    if(spd > 60 && spd <= 80){ return '#FF8000' }
    if(spd > 80 && spd <= 100){ return '#DF01A5' }
    return '#FF0000'
}